import { cn } from '@cardo/lib';
import DOMPurify from 'isomorphic-dompurify';

interface HTMLContentProps {
  content: string;
  heading?: string;
  className?: string;
}

export default function HTMLContent({ content, className }: HTMLContentProps) {
  return (
    <div
      className={cn(
        'prose max-w-none prose-figure:mx-auto prose-img:shadow-lg',
        !className?.includes('text-') && 'text-theme-blue-darkest',
        className
      )}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    ></div>
  );
}
